import * as React from 'react';

import { StaticQuery, graphql } from 'gatsby';


import { NHeader } from '../ncomponents/shared/header/header';
import { Footer2 } from '../components/shared/Footer/Footer2';
import CountdownTimer from '../components/shared/CountdownTimer/CountdownTimer';
import { NServices } from '../ncomponents/shared/services/services';
import { NSiteCTA } from '../ncomponents/shared/sitecta/sitecta';
import { NFooter } from '../ncomponents/shared/footer/footer';

import '../../node_modules/normalize.css/normalize.css';
import { AnnouncementBanner } from '../components/shared/AnnouncementBanner/AnnouncementBanner';


//import '../css/styles.css';

/*
import '/css/normalize.css';
import '/css/webflow.css';
import '/css/custom.css';
*/


export const MainLayout: React.SFC = ({ children }) => (
  <StaticQuery
    query={graphql`
          query MainLayoutQuery {
            site {
              siteMetadata {
                siteName
              }
            }
          }
        `}
    render={data => (

      <div>
        <NHeader />

        {children}

        <NFooter />

      </div>

    )}
  />
);
