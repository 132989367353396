import * as React from 'react';
import styled from 'styled-components';

import { DEBUG_LAYOUT, debugBorderRed } from '../../../global/layout-debug';

const MENU_ANIMATION_DURATION = 20;

interface NHeaderProps {
    style?: React.CSSProperties;
}
interface NHeaderState {
    isMenuOpen: boolean;
}

export  class NHeader extends React.Component<NHeaderProps, NHeaderState> {

    private isMenuToggling: boolean = false;

    constructor(props: NHeaderProps) {
        super(props);
        this.state = {
            isMenuOpen: false
        };
    }

    public onMenuButtonClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        if (this.isMenuToggling) {
            return;
        }
        this.isMenuToggling = true;
        this.setState({
            isMenuOpen: !this.state.isMenuOpen
        });
        setTimeout(()=>{
            this.isMenuToggling = false;
        }, MENU_ANIMATION_DURATION);
    }

    public render() {
        return (
            <div data-collapse="medium" data-animation="default" data-duration={400} className="navigation w-nav">
                <div className="navigation-wrap">
                    <a href="/" className="logo-link w-nav-brand w--current">
                        <img src="/images/Nuvious-logo.svg" width={242} alt="" className="logo-image" />
                    </a>
                    <div className="menu">
                        <nav role="navigation" className="navigation-items w-nav-menu">
                            <a href="/services" className="navigation-item w-nav-link">services</a>
                            <a href="/about" className="navigation-item w-nav-link">ABOUT</a>
                            <a href="/contact" className="navigation-item w-nav-link">Contact</a></nav>
                        <div className="menu-button w-nav-button" onClick={(e)=>this.onMenuButtonClick(e)}>
                            <img src="/images/menu-icon_1menu-icon.png" width={22} alt="" className="menu-icon" />
                        </div>
                    </div>
                    <a href="/contact" className="buttonblue cc-contact-us w-inline-block">
                        <div>Contact US</div>
                    </a>
                </div>
                
    
                <div className="w-nav-overlay" data-wf-ignore style={{height: '4170.23px', display: this.state.isMenuOpen ? 'block' : 'none'}}>
                    <nav role="navigation" className="navigation-items w-nav-menu w--nav-menu-open" style={{transform: 'translateY(0px) translateX(0px)', transition: 'transform 400ms ease 0s'}}>
                        <a href="/services" className="navigation-item w-nav-link w--nav-link-open">services</a>
                        <a href="/about" className="navigation-item w-nav-link w--nav-link-open">ABOUT</a>
                        <a href="/contact" className="navigation-item w-nav-link w--nav-link-open">Contact</a>
                    </nav>
                </div>
            </div>
        );
    }
}
