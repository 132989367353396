import * as React from 'react';
import styled from 'styled-components';

import { DEBUG_LAYOUT, debugBorderRed } from '../../../global/layout-debug';



interface NFooterProps {
    style?: React.CSSProperties;
}

export const NFooter: React.StatelessComponent<
    NFooterProps
> = (props: NFooterProps) => {

    // This combines the incoming style with the debug border
    const style = { ...(props.style ? props.style : {}), ...(DEBUG_LAYOUT ? debugBorderRed : {}) };

    return (
        <div className="section">
            <div className="container">
                <div className="footer-wrap"><a href="https://webflow.com/" target="_blank" className="webflow-link w-inline-block"><img src="images/webflow-w-small2x_1webflow-w-small@2x.png" width={15} alt="" className="webflow-logo-tiny" /><div className="paragraph-tiny">Nuvious LLC</div></a></div>
            </div>
        </div>
    );
};
